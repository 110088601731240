@use "~scss/settings" as *;

.anys-dash-question {
  @include flex(column);
  width: 100%;

  &__header {
    @include flex(row, space-between, center);

    border-bottom: 1px solid rgb(189, 189, 189);

    &__delete {
      svg {
        @include size(18px);

        margin-right: 10px;
        cursor: pointer;
        fill: $color-red-FF64;
        &:hover {
          fill: $color-red-FF64;
        }
      }
    }

    &__edit {
      svg {
        @include size(18px);

        margin-right: 10px;
        fill: $color-blue-17;
      }
    }

    &__title {
      width: 100%;
      background-color: transparent;
      cursor: pointer;
      font-size: 25px;
      border: none;

      svg {
        font-size: 20px;
        margin-left: 24px;
      }

      &__txt {
        @include flex(row, undefined, center);
      }
    }

    h3 {
      font-size: 18px;
      color: $color-gray-61-65;
    }
  }

  &__answer {
    padding: 10px 20px 0;
  }

  &__modal {
    &__title {
      @include flex(row, center, center);
    }

    &__actions {
      @include flex(row, space-evenly);
      width: 100%;
      margin-top: 24px;
    }

    .anys-input{
      margin-bottom: 18px;
    }
  }
}
