@use './tools/t-font-size' as *;

@mixin label-style($isLarge: false) {
  @if $isLarge {
    @include font(22px, 26px, 600);

    margin-bottom: 10px;
  } @else {
    @include font(14px, 20px, 600);
  }

  color: var(--color-text);
}
