@use "~scss/settings" as *;

.anys-report-page {
  &__filter {
    display: grid;
    grid-template-rows: 2fr 1fr;
    padding: 64px;

    &__dates {
      @include flex();
      gap: 32px;

      .anys-calendar-dropdown {
        max-width: 320px;
      }
    }

    &__skill-loc {
      @include flex(row, flex-start, flex-end);
      gap: 64px;
    }

    &__skill,
    &__location {
      max-width: 320px;
    }
  }

  .ncoded-input.ncoded-input--has-suffix {
    input {
      padding-top: 10px !important;
    }
  }

  &__charts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 120px;

    > div {
      padding: 0 64px 32px;
    }

    &__total {
      margin-top: 24px;
    }
  }

  &__job-type {
    max-width: calc(50% - 64px);
    margin-left: auto;

    .ncoded-select {
      max-width: 250px;
    }
  }
}
