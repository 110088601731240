@use '~scss/settings' as *;

.anys-input {
  display: flex;
  flex-direction: column;

  &__label {
    display: inline-flex;
    flex-direction: column;
    margin-bottom: 6px;

    &__description {
      @include font(14px, 20px, 400);

      color: var(--color-text-light);
      margin-top: 2px;

      &--large-spacing {
        margin-top: 6px;
      }
    }

    &--large {
      @include label-style(true);
    }

    &__required {
      color: $color-purple-D3;
    }
  }

  &__input {
    &--no-arrows {
      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type='number'] {
        -moz-appearance: textfield;
      }
    }
  }

  &__description {
    @include font(14px, 20px);

    color: var(--color-text-light);
    margin-top: 10px;
  }
}
