@use "~scss/settings" as *;

.anys-dash-questions-section {
  margin-bottom: 30px;

  color: $color-gray-61-65;

  &__title {
    @include flex("row", flex-start, center);

    h2 {
      font-size: 32px;
      color: $color-gray-61-65;
    }

    button {
      margin-left: 12px;
      svg {
        @include size(30px);
      }
    }
  }
}

.ncoded-modal {
  &__content {
    min-height: 200px;
  }
}
