$color-primary: #4caffb;
$color-primaty-dt: #7ec6ff;
$color-primary-5E: #5e9df9;

$color-eaf7fe: #eaf7fe;
$color-bce5fc: #bce5fc;

$color-white: #fff;
$color-white_05: rgba(255, 255, 255, 0.5);
$color-white_09: rgba(255, 255, 255, 0.9);
$color-white_035: rgba(255, 255, 255, 0.35);
$color-white_02: rgba(255, 255, 255, 0.2);

$color-purple-A7: #a761f5;
$color-purple-A5: #a562f5;
$color-purple-A5_RGB: rgb(165, 98, 245);
$color-purple-A6_03: rgba(165, 98, 245, 0.3);
$color-purple-D1: #d142f4;
$color-purple-D3: #d344f1;
$color-purple-EE: #eef2fd;

$color-pink-FF: #ff7bac;

$color-blue-50: #50a8fa;
$color-blue-01: #01e8fe;
$color-blue-17: #1777d0;
$color-blue-E7: #e7f3fe;

$color-green-EB: #ebf4ec;
$color-green-2D: #2d7738;
$color-green-g100: #1cad2f;

$color-gray-light: #d0d1d4;
$color-gray-dark: #909194;
$color-red-FF45: #ff452c;
$color-red-FFED: #ffedeb;
$color-orange-FFF: #fff4e6;
$color-orange-FFF-dark: #3d3223;
$color-red-FF64: #ff644f;
$color-red-EF: #ef4038;
$color-red-E7: #e73631;

$light-background: #fbfcfe;
$light-onbackground: #2e3133;
$light-primary: #4caffb;

$color-gray-22: #222;
$color-gray-25: #252a31;
$color-gray-44: #444;
$color-gray-48: #484848;
$color-gray-4F: #4f4f4f;
$color-gray-4F5: #4f5e71;
$color-gray-45: #454749;
$color-gray-56: #565656;
$color-gray-5: #5c5c5c;
$color-gray-5C: #5c5e61;
$color-brown-5C: #5c320b;
$color-brown-5C-dark: mix($color-brown-5C, white, 60%);
$color-gray-7C: #7c7c7c;
$color-gray-61: #616161;
$color-gray-61-65: #61656a;
$color-gray-66: #666;
$color-gray-82: #828282;
$color-gray-99: #999;
$color-gray-95: #95989b;
$color-gray-E4E6: #e4e6e4;
$color-gray-e6: #e6e6e6;
$color-gray-e7: #e7e7e7;
$color-gray-f2f3: #f2f3f7;
$color-gray-f2f3f8: #f2f3f8;
$color-gray-f6: #f6f6f6;
$color-gray-f9f: #f9fafb;
$color-gray-b2: #b2b2b2;
$color-gray-bd: #bdbdbd;
$color-gray-aa: #aaa;
$color-gray-de: #dedede;
$color-gray-E0: #e0e0e0;
$color-gray-f9: #f9f9f9;
$color-gray-E6: #e6e6e6;
$color-gray-FB: #fbfcff;
$color-gray-D9: #d9d9d9;

$color-gray-E8: #e8e7e7;
$color-gray-ED: #ededed;
$color-gray-7C_05: rgba(124, 124, 124, 0.5);
$color-gray-3A: #3a3f45;
$color-gray-3A_08: rgba(58, 63, 69, 0.8);
$color-gray-3A_04: rgba(58, 63, 69, 0.4);
$color-gray-90: #909194;
$color-gray-5: #5a5a5a;
$color-gray-90_04: rgba(144, 145, 148, 0.4);
$color-gray-05: #efefef;
$color-gray-5C5C: #5c5c5c;
$color-gray-5D: #5d5d60;
$color-gray-5: #5a5a5a;
$color-gray-E1: #e1e2e5;
$color-gray-E2: #e2e8f0;
$color-gray-C4: #c4c4c4;
$color-gray-C0: #c0c2c3;
$hover-bg-gray: #dedede80;

$color-black: #000;
$color-black-00_A3: #000000a3;
$color-gray-F4: #020202;
$color-black-1A: #1a0042;
$color-black-1F: #1f242a;
$color-black-35: #353937;
$color-input-dark-bg: #3e4247;

$color-dark-28: #282d33;

