@use 'color' as *;

$header-height: 64px;
$header-height-mobile: 84px;
$topbar-height-mobile: 50px;
$bottom-bar-height: 60px;
$global-font-size: 16;
$overlay-window-z: 5;
$menu-z-index: 10;
$modal-z-index: 900;
$border-radius: 10px;
$border-radius-large: 18px;
$border-radius-xlarge: 22px;
$card-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05);
$card-shadow-dt: 0px 2px 4px rgba(255, 255, 255, 0.15);
$padding-169: 56.25%;
$box-shadow: 4px 4px 10px $color-purple-A6_03;
$shadow: 0px 8px 17px 6px rgba(0, 0, 0, 0.05), 0px 4px 9px rgba(0, 0, 0, 0.1);
$primary-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1),
  0px 2px 6px 2px rgba(0, 0, 0, 0.07);
$input-height: 44px;
$airbnb-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
$text-area-min-width: 220px;
$text-area-min-height: 2 * $input-height;
$purple-even-shadow: 0px 0px 10px $color-purple-A5;

$mobile-spacing: 20px 24px;
$desktop-spacing: 30px 60px;

$padding-main-horizontal: max(16px, min(60px, 3.5vw));
$column-gap: max(16px, min(45px, 3vw));
$modal-drawer-padding: 40vh $padding-main-horizontal 0;
$control-shadow-color: rgba(165, 98, 245, 0.24);
