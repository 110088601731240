@use 'color' as *;
@use 'variables' as *;
@use 'tools/t-size' as *;
@use 'tools/t-font-size' as *;
@use 'linear-gradient' as *;
@use 'font' as *;
@use 'breakpoints' as *;

.ncoded-select {
  cursor: pointer;
  --shadow-color: #{$control-shadow-color};

  &__selected {
    font-size: var(--font-size);
    border-color: var(--border-color);
    min-height: $input-height;
    height: unset;
  }

  &__placeholder {
    & > .ncoded-select__content {
      color: var(--color-text-light);
    }
  }

  &:focus-within .ncoded-select__selected {
    box-shadow: 0px 0px 0px 2px var(--shadow-color);
  }

  & .dropdown-icon {
    color: var(--color-primary-pink);
  }

  &--error {
    --shadow-color: rgba(255, 69, 44, 0.24);

    .ncoded-select__selected {
      border-color: var(--color-error);
    }
  }

  &:not(&--disabled) {
    & .ncoded-select__selected {
      background-color: var(--color-input-bg);
    }

    .ncoded-select__placeholder {
      & > .ncoded-select__content {
        opacity: 1;
      }
    }
  }

  &--disabled {
    cursor: not-allowed;
    background-color: var(--color-bg);

    @include light {
      opacity: 1;

      svg {
        opacity: 0.75;
      }
    }
  }

  &__multiple-selected {
    margin-left: px-to-rem(6px);
  }

  &__multiple-selected {
    @include font(14px, 18px, 500);

    background: $linear-gradient-pink;
    padding: 0 px-to-rem(5px);
    border-radius: px-to-rem(30px);
    color: $color-white;
    margin-right: 0;
  }

  .dropdown__content {
    border-color: var(--border-color);
  }

  &__option {
    @extend %font-standard;

    &--selected {
      padding-right: px-to-rem(24px);
    }

    &:after {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
    }
  }
}
