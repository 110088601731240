@use '~scss/settings' as *;

.anys-calendar-dropdown {
  @include flex(column);

  width: 100%;

  & .dropdown {
    width: 100%;

    &__trigger {
      width: 100%;
    }
  }

  &__label {
    margin-bottom: 6px;
  }

  &__trigger {
    @extend %button-reset;
    @include font-size(16px, 20px);
    @include flex(row, space-between, center);
    @include size(100%, $input-height);

    padding: 12px;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    background-color: var(--color-input-bg);
    text-align: left;

    &:focus-within {
      border-width: 2px;
      padding: 11px;
    }

    &--placeholder {
      color: var(--color-text-light);
    }

    &--error {
      border-color: var(--color-error);
    }

    &--plain {
      border: 0;
      border-radius: 0;
      background-color: unset;
    }

    &--disabled {
      opacity: 0.5;
    }

    &:not(&--error):focus-within {
      border-color: var(--color-primary);
    }

    &__value {
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__icon {
      margin-left: 12px;
      min-width: 18px;
    }
  }

  &__mark-dates-checkbox {
    .anys-calendar__table {
      padding-bottom: 48px;
    }
  }
  &__checkbox {
    position: absolute;
    bottom: 16px;
    left: 16px;
    z-index: 1;
  }
}
