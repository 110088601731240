@use '~scss/settings' as *;

.anys-password-field {
  @include flex(column);

  &__input {
    position: relative;

    & .ncoded-input--has-suffix {
      padding-right: 0;
    }
  }

  &__eye {
    @extend %button-reset;
    @include flex(row, center, center);

    padding: 10px;
    position: absolute;
    right: 0;
  }

  &__strength-info {
    font-size: 14px;
    color: $color-gray-99;
  }
}
