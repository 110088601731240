@use "~scss/settings" as *;

.anys-user-arbitration-page {
  @include flex(column, center, center);

  &__verified {
    @include flex(row, flex-start, center);

    gap: 8px;
  }

  &__content {
    @include flex(column);
    width: 800px;
    border: 1px solid rgb(209, 209, 209);
    padding: 0 25px 25px;
    border-radius: 8px;
    margin: 30px 0;

    h1 {
      @include font(50px, 50px, 600);
    }
    &__title {
      @include flex(row, space-between, center);
    }

    &__document-verification {
      gap: 30px;
      h1 {
        color: $color-gray-61-65;
        font-size: 36px;
        margin-top: 0;
      }

      &__files {
        @include flex(column);
        padding: 10px;
        width: 100%;
      }
    }
  }
}
