%ul-reset {
  padding-inline-start: 0;
  list-style: none;
  margin: 0;
}

%button-reset {
  display: inline-block;
  outline: none;
  border: none;
  font: inherit;
  background: unset;
  color: inherit;
  padding: 0;

  &:focus {
    outline: none;
  }

  &:enabled {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

%text-gradient-reset {
  background: unset;
  background-clip: unset;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
  color: unset;
  display: unset;
}

%input-reset {
  border: none;

  > input {
    border: none;
    padding: 0;
  }
}
