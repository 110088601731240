@use "~scss/settings" as *;

.users-page {
  margin: 64px;

  &__filters {
    @include flex(row, flex-start, center);

    gap: 24px;
    margin-bottom: 24px;

    .ncoded-input {
      width: 100%;
      max-width: 260px;
    }
  }
}

.p-2 {
  margin: 64px;
}

.anys-dash__table {
  table {
    border-collapse: collapse;
    border-spacing: 0;
    font-family: arial, sans-serif;
    width: calc(100% - 200px);
    border-radius: 8px;

    .ncoded-button {
      margin-right: 10px;
    }
  }

  thead {
    background: $linear-gradient-purple;
    margin: 0;
    position: sticky;
    top: 0;
    height: 60px;
    color: white;

    th {
      border: none;
      padding: 12px;
    }
  }

  tr {
    &:nth-of-type(even) {
      background-color: $color-eaf7fe;
    }
  }

  th {
    border-bottom: 1px solid lightgray;
    border-right: 1px solid lightgray;
    padding: 2px 4px;
    text-align: left;
  }

  td {
    padding: 6px;
  }

  // .container {
  //   border: 1px solid lightgray;
  //   height: 500px;
  //   max-width: 900px !important;
  //   overflow: auto;
  // }

  .gap-2 {
    margin: 24px 100px;
  }
}
