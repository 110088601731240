@use 'color' as *;

$linear-gradient-purple: linear-gradient(
  88.98deg,
  $color-purple-A7 0%,
  $color-blue-50 100%
);
$linear-gradient-pink: linear-gradient(
  90deg,
  $color-pink-FF 0%,
  $color-purple-D1 100%
);
$linear-gradient-full: linear-gradient(
  90deg,
  $color-pink-FF 0%,
  $color-purple-D1 50%,
  $color-blue-50 79%,
  $color-blue-01 100%
);

@mixin text-gradient($isPrimary: true) {
  @if $isPrimary {
    background: $linear-gradient-purple;
  } @else {
    background: $linear-gradient-pink;
  }

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  display: inline-block;
}

// Gradient on Safari buttons only works
// if you apply it to the element directly.
@mixin safari-text-gradient($isPrimary: true) {
  &:not(:disabled) > * {
    @include text-gradient($isPrimary);
  }
}

@mixin border-gradient(
  $type: 'full',
  $borderWidth: 1px,
  $borderRadius: null,
  $underline: false,
  $correction: false
) {
  &::before {
    content: '';
    position: absolute;
    @if $underline == false {
      top: 0;
    }
    right: 0;
    bottom: 0;
    left: 0;
    @if $borderRadius {
      border-radius: $borderRadius;
    } @else {
      border-radius: inherit;
    }
    padding: $borderWidth;

    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;

    @if $type == 'full' {
      background: $linear-gradient-full;
    } @else if $type == 'purple' {
      background: $linear-gradient-purple;
    } @else {
      background: $linear-gradient-pink;
    }

    @if $correction {
      width: 100%;
      height: 100%;
      transform: translate(-1px, 1px);
    }
  }

  position: relative;
}
