@use "~scss/settings" as *;

.anys-file-item {
  @include flex(row, center, center);

  background-color: $color-gray-f2f3f8;
  border-radius: $border-radius;
  color: $color-gray-22;
  padding: 8px;
  text-decoration: none;
  cursor: pointer;

  p {
    margin-left: 15px;
  }

  &__name {
    @include font(12px, 16px, 500);
    margin-top: 8px;
    width: 100%;
  }
}
