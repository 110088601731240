@use "~scss/settings" as *;

nav {
  @include flex(row, space-between, center);

  width: 100%;
  height: 80px;
  background: $linear-gradient-full;
  padding-right: 36px;

  .anys-navigation {
    &__links {
      @include flex();
      flex: 1;
    }
  }

  a {
    text-decoration: none;
    flex: 1;
    text-align: center;
    color: white;
    margin: 0 12px;
    padding: 12px;
    width: fit-content;
    max-width: 130px;

    &.active {
      @include border-gradient("purple", 2px, 8px, true);

      border-bottom: 1px solid $linear-gradient-purple;
    }
  }
}
