@use '~scss/settings' as *;

/* LOCATION PREFILL */
.pac-container:after {
  /* hide 'powered by Google' text at the bottom */
  background-image: none !important;
  height: 0;
}

.pac-container {
  box-shadow: 0px 1px 6px 0px #38434b1f;
  border-radius: 8px;
  margin-top: 4px;
  border-top: unset;
  min-width: 200px;

  .pac-item {
    height: 48px;
    border: none;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &:hover {
      background-color: $color-gray-b2;
    }

    span:last-child {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .pac-icon {
      background: url('../../../public/assets/icons/location-marker.svg')
        no-repeat center;
      background-size: 12px;
      margin: 5px 6px 6px 0;
      vertical-align: middle;
      padding-right: 12px;
      padding-left: 12px;
    }

    &__my-current-loc {
      @include text-gradient(false);
      cursor: pointer;

      background-size: 12px;
      margin: 5px 6px 6px 0;
      vertical-align: middle;
      padding-left: 8px;
      font-size: 14px;
      display: flex !important;
      img{
        margin-right: 9px;
      }
    }
  }
}
