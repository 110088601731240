@use "~scss/settings" as *;
@use "sass:math";
@import url("./scss/_select.scss");

html,
body,
#root {
  @include size(100%);

  scroll-behavior: smooth;

  @supports (-webkit-touch-callout: none) {
    min-height: calc(100% - env(safe-area-inset-bottom, 0px));
  }
}

* {
  box-sizing: border-box;
}
