@use "~scss/settings" as *;

.anys-login {
  width: 600px;
  margin: auto;
  transform: translateY(50%);

  &__logo {
    margin: auto;
    display: flex;
  }

  h2 {
    text-align: center;
    margin-top: 32px;
  }

  .anys-login-form {
    max-width: 360px;
    margin: auto;
  }

  &__login-btn {
    margin: auto;
    margin-top: 32px;
  }
}
