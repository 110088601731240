@use "~scss/settings" as *;

.anys-files-section {
  &__category-name {
    @include flex(row, space-between, center);
    cursor: pointer;
    background: transparent;
    border: none;
    width: 100%;

    h2 {
      margin-bottom: 15px;
      color: $color-gray-61-65;
      font-size: 30px;
    }
  }
  &__content {
    &__header {
      @include flex(row, flex-start, center);
      padding-left: 20px;
      h3 {
        color: $color-gray-61-65;
      }

      button {
        @include flex(row, center, center);

        border: 1px solid rgb(189, 189, 189);
        background: transparent;
        border-radius: 20px;
        margin-left: 10px;
        height: 30px;
        cursor: pointer;
        &:hover {
          background-color: #e2e2e2;
        }
      }
    }

    &__files {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      margin: 0 25px;
      padding: 0 5px 10px;
      &:not(:last-of-type) {
        border-bottom: 1px solid rgb(189, 189, 189);
      }
    }
  }
  &:not(:last-of-type) {
    border-bottom: 1px solid rgb(189, 189, 189);
  }
}
