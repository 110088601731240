@use '~scss/settings' as *;

.anys-login-form {
  @include flex(column);

  @include above-phablet {
    width: 100%;
  }

  & > *:not(:last-child) {
    margin-bottom: 10px;
  }

  &__remember-me {
    @include flex(row, space-between, center);
    padding: 4px 0 16px;

    @include phablet {
      align-self: flex-end;
    }
  }

  &__forgot-password {
    &.ncoded-button {
      font-size: 14px;
      font-family: $font-family;
      padding: 0;
    }
  }
}
