@use "~scss/settings" as *;

.anys-dash-static-pages {
  @include flex(column, center, center);

  &__content {
    @include flex(column);

    width: 800px;
    margin-top: 30px;

    &__title {
      @include flex(row, space-between, center);

      h1 {
        color: $color-gray-61-65;
        font-size: 46px;
      }
    }

    &__sections {
      border: 1px solid rgb(209, 209, 209);
      padding: 25px;
      border-radius: 8px;
    }
  }
}
