@use "~scss/settings" as *;

.create-skill-btn {
  margin-left: 100px;

  &__in-modal {
    margin-top: 12px;
  }
}

.skills-page {
  &__filters {
    margin-bottom: 24px;
  }
}
