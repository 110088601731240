@use '~scss/settings' as *;

.anys-calendar {
  background-color: var(--color-box-bg);
  font-size: 14px;
  font-weight: 500;
  color: var(--color-text);
  border-spacing: 4px;
  z-index: 1;
  position: relative;
  width: fit-content;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);

  &__head {
    background-color: var(--color-box-bg);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;

    padding: 16px;

    &__month {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      flex: 1;

      svg {
        margin-left: 10px;
      }
    }

    &__close-btn {
      align-self: flex-end;
      &.ncoded-button.ncoded-button--primary {
        background: var(--color-box-bg);
      }

      + span {
        align-items: baseline;
      }
    }
  }

  .anys-calendar__table {
    padding: 16px;

    &__close-button {
      margin-left: auto;
      margin-right: 8px;
    }
    &__head {
      &__commands {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &__command {
          &.ncoded-button--icon {
            background: var(--color-box-bg);
          }
          border-radius: 50%;

          &:first-of-type {
            margin-right: 12px;
            svg {
              transform: rotate(90deg);
            }
          }
          svg {
            transform: rotate(-90deg);
          }
        }
        &__done-btn {
          &.ncoded-button {
            font-size: 14px;
            min-width: fit-content;
            padding: 10px 20px;
            line-height: unset;
          }
        }
      }
    }

    &__day {
      position: relative;
      cursor: pointer;

      &__current {
        background: $linear-gradient-purple;
        color: white !important;
        border-radius: 50%;

        &::after {
          display: none;
        }
      }

      &:hover {
        @include border-gradient(null, 2px, 50%);
      }

      &--weekend {
        color: var(--color-text);
      }

      &--selected {
        color: white;
        background: $linear-gradient-pink;
        border-radius: 50%;

        &.anys-calendar__table__day--hint {
          &:after {
            display: none;
          }
        }
      }

      &--disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      &--hint {
        &.anys-calendar__table__day--selected {
          border: 2px solid #1cad2f;
          background: transparent;
          color: var(--color-text);
        }
        &:after {
          content: '';
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: #1cad2f;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    &__year {
      min-width: 48px;
      cursor: pointer;

      &--selected {
        color: $color-white;
        background-color: $color-blue-17;
        border-radius: 15px;
      }

      &--disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    &__month {
      min-width: 48px;
      cursor: pointer;

      &__year {
        text-align: left;
        color: grey;
      }

      &--selected {
        color: $color-white;
        background-color: $color-blue-17;
        border-radius: 15px;
      }

      &--disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

  .anys-calendar__table {
    table {
      width: 100%;
    }
    &--portal {
      border: none;
      margin-top: 0;
    }

    &--bottom-right {
      &:before,
      &:after {
        left: unset;
        right: 24px;
      }
    }

    &--years {
      border-spacing: 10px;
    }

    &--months {
      border-spacing: 4px;
    }

    &--mobile {
      width: 100%;
      margin-top: unset;

      &:before,
      &:after {
        display: none;
      }
    }
  }

  td {
    height: 36px;
    min-width: 36px;
    text-align: center;
  }
}
